import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components";

const TokenContext = React.createContext(undefined);

export const TokenProvider = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let isCurrent = true;
    (async function fetchToken() {
      setLoading(true);

      const token = await getAccessTokenSilently({
        audience: `https://api.liteboxer.com`,
      });
      if (isCurrent) {
        setToken(token);
        setLoading(false);
      }
    })();

    return () => {
      isCurrent = false;
    };
  }, [getAccessTokenSilently]);

  if (loading || token === undefined) {
    return <Loading />;
  } else {
    return <TokenContext.Provider value={token} {...props} />;
  }
};
export const useToken = () => {
  const context = React.useContext(TokenContext);
  if (context === undefined) {
    throw new Error(`useToken must be used within a TokenProvider`);
  }
  return context;
};
