import React from "react";
import Container from "@mui/material/Container";

import { Footer } from "./components";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";

import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const is_prod = process.env.REACT_APP_IS_PROD === "true";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const App = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* on smaller screens show page title and a menu for nav */}
          <Box display={{ xs: "inherit" }} style={{ alignItems: "center" }}>
            <img
              src={
                process.env.PUBLIC_URL + "/Liteboxer.App.RGB.White-Black.png"
              }
              style={{ height: "36px" }}
              alt=""
            />
            &nbsp; &nbsp;
            <Typography variant="h6">Liteboxer Cast Test</Typography>
          </Box>

          <div style={{ flexGrow: 1 }} />
          {!is_prod && <Chip color="secondary" label="Development Test" />}
          <div style={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <Container className="flex-grow-1 mt-5" style={{ marginTop: "10px", textAlign: "center" }}>
        <img
          src={
            process.env.PUBLIC_URL + "/HeadUnitSVG.svg"
          }
          style={{ height: "auto" }}
          alt=""
        />
      </Container>
      <Footer />
    </>
  );
};

export default App;
