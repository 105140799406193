import React from "react";
import ReactDOM from "react-dom";
import App from "./app";

import "./index.css";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

const is_prod = process.env.REACT_APP_IS_PROD === "true";

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_green = {
  light: "#58dd50",
  main: "#01aa1b",
  dark: "#007900",
  contrastText: "#FFFFFF",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_green : theme_orange,
    secondary: theme_black,
    green: theme_green,
    orange: theme_orange,
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
      marginTop: "10px",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.6rem",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
