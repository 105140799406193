import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useFetch = (apiPath) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!apiPath) return;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const token = await getAccessTokenSilently({
          audience: `https://api.liteboxer.com`,
        });

        const response = await fetch(`${apiUrl}${apiPath}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();

        if (responseData.status === "Unauthorized") {
          throw new Error("Unauthorized");
        }

        setData(responseData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [apiPath, getAccessTokenSilently]);

  return { data, error };
};
