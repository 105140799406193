import { Chip, Hidden, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from '@mui/material/Checkbox';
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Autocomplete from "@mui/material/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    /* onSelectAllClick,*/ order,
    orderBy,
    /* numSelected, rowCount, */ onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
        {props.headCells.map((headCell, index) => (
          <Hidden mdDown={headCell.widescreenOnly} key={headCell.id + index}>
            <TableCell
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : undefined}
              sortDirection={orderBy === headCell.id ? order : false}
              display={{ xs: "none", md: "inherit" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </Hidden>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/* Do we need a title? */}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function SearchTable(props) {
  const { rows, headings } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState([]);
  const history = useHistory();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    props.history.push(`${props.history.location.pathname}/${name}/details`);
    // console.log(event);
    // console.log(name);

    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //     newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //     newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //     newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //     newSelected = newSelected.concat(
    //         selected.slice(0, selectedIndex),
    //         selected.slice(selectedIndex + 1),
    //     );
    // }

    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleFilterChange = (_event, value) => {
    setFilter(value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const filterRows = filter.reduce((result, f) => {
    return result.filter((row) => {
      // If it's a filter with a specific column, see if we contain that
      // Otherwise, see if any value contains it
      return (
        (f.id &&
          f.val &&
          row[f.id]
            .toString()
            .toLowerCase()
            .includes(f.val.toString().toLowerCase())) ||
        (!f.id &&
          Object.values(row).find((v) => v && v.includes && v.includes(f)))
      );
    });
  }, rows);

  // Starts with {col:, val:} dict for each heading/value
  const filterOptions = filterRows
    .flatMap((row) => {
      return headings.reduce((result, heading) => {
        let val = row[heading.id];
        if (heading.label && val) {
          result.push({
            col: heading.label,
            id: heading.id,
            val: val.toString().toLowerCase(),
            formatted: heading.format ? heading.format(val) : val,
          });
        }

        return result;
      }, []);
      // Remove duplicates
    })
    .filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(
            ["val", "col"].map((k) => o[k]).join("|")
          )
      )(new Set())
      // Sort by column, then by val
    )
    .sort((a, b) => {
      if (a.col === b.col) {
        return a.val < b.val ? -1 : 1;
      } else {
        return a.col < b.col ? -1 : 1;
      }
    });

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, filterRows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Autocomplete
          multiple
          options={filterOptions}
          getOptionLabel={(option) => option.val}
          groupBy={(option) => option.col}
          freeSolo
          onChange={handleFilterChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) =>
              !option.formatted || typeof option.formatted === "string" ? (
                <Chip
                  label={
                    option.col && option.formatted
                      ? option.col + ": " + option.formatted
                      : option
                  }
                  {...getTagProps({ index })}
                />
              ) : (
                React.cloneElement(option.formatted, {
                  size: "medium",
                  ...getTagProps({ index }),
                })
              )
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter"
              placeholder="enter a filter..."
              color="secondary"
            />
          )}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headings}
            />
            <TableBody>
              {stableSort(filterRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  //const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> */}

                      {headings.map((heading, index) => {
                        var val = row[heading.id];

                        if (heading.format) {
                          val = heading.format(val);
                        } else if (val) {
                          val = val.toString();
                        } else {
                          val = "";
                        }

                        return (
                          <Hidden mdDown={heading.widescreenOnly} key={index}>
                            <TableCell
                              align={heading.numeric ? "right" : "left"}
                              padding={
                                heading.disablePadding ? "none" : "normal"
                              }
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {val}
                            </TableCell>
                          </Hidden>
                        );
                      })}
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            history.push(
                              `${props.history.location.pathname}/${row.id}`
                            );
                          }}
                          startIcon={<CreateIcon />}
                        >
                          Edit
                        </Button>
                        {props.umgAssociations ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              history.push(
                                `${props.history.location.pathname}/${row.id}/umg`
                              );
                            }}
                            startIcon={<CreateIcon />}
                          >
                            UMG Associations
                          </Button>
                        ) : null}
                      </TableCell>
                      {/* // <TableCell component="th" id={labelId} scope="row" padding="none">
                                            //     {row.id}
                                            // </TableCell>
                                            // <TableCell align="right">{row.calories}</TableCell>
                                            // <TableCell align="right">{row.fat}</TableCell>
                                            // <TableCell align="right">{row.carbs}</TableCell>
                                            // <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {props.showDenseSwitch && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
    </div>
  );
}
